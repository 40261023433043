/*! CSS Used from: https://cdnjs.cloudflare.com/ajax/libs/bootswatch/5.2.3/morph/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
ul{padding-left:2rem;}
ul{margin-top:0;margin-bottom:1rem;}
a{color:var(--bs-link-color);text-decoration:underline;}
a:hover{color:var(--bs-link-hover-color);}
img{vertical-align:middle;}
button{border-radius:0;}
button:focus:not(:focus-visible){outline:0;}
button{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
button{text-transform:none;}
button{-webkit-appearance:button;}
button:not(:disabled){cursor:pointer;}
.alert{--bs-alert-bg:transparent;--bs-alert-padding-x:1rem;--bs-alert-padding-y:1rem;--bs-alert-margin-bottom:1rem;--bs-alert-color:inherit;--bs-alert-border-color:transparent;--bs-alert-border:0 solid var(--bs-alert-border-color);--bs-alert-border-radius:0.375rem;position:relative;padding:var(--bs-alert-padding-y) var(--bs-alert-padding-x);margin-bottom:var(--bs-alert-margin-bottom);color:var(--bs-alert-color);background-color:var(--bs-alert-bg);border:var(--bs-alert-border);border-radius:var(--bs-alert-border-radius);}
.alert{box-shadow:8px 8px 40px rgba(0,0,0,.15),inset 1px 1px 3px rgba(255,255,255,.5),inset -5px -5px 20px rgba(0,0,0,.05);-webkit-backdrop-filter:blur(3px);backdrop-filter:blur(3px);}
/*! CSS Used from: Embedded */
.ng-hide:not(.ng-hide-animate){display:none!important;}
/*! CSS Used from: https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/71973-9774406a534671fc.css */
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
/*! CSS Used from: https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/app-9774406a534671fc.css */
aside,header,nav{display:block;}
a{background-color:transparent;}
a:active,a:hover{outline:0;}
img{border:0;}
button{color:inherit;font:inherit;margin:0;}
button{overflow:visible;}
button{text-transform:none;}
button{-webkit-appearance:button;cursor:pointer;}
*,*:before,*:after{box-sizing:inherit;}
a:hover{cursor:pointer;}
img{max-width:100%;height:auto;}
img{-ms-interpolation-mode:bicubic;}
img{display:inline-block;vertical-align:middle;}
.label{display:inline-block;font-family:"Open Sans",sans-serif;font-weight:400;line-height:1;margin-bottom:auto;position:relative;text-align:center;text-decoration:none;white-space:nowrap;padding:.3125rem .5rem;font-size:.6875rem;background-color:#1874a4;color:#fff;}
.label.round{border-radius:1000px;}
.label.alert{background-color:#ff3b30;color:#fff;}
ul,li{margin:0;padding:0;}
div{margin:0;padding:0;}
a{color:#2075a3;line-height:inherit;text-decoration:underline;}
a:hover,a:focus{color:#1a5e82;}
a img{border:none;}
ul{font-family:inherit;font-size:.875rem;line-height:1.6;list-style-position:outside;margin-bottom:1.25rem;}
ul{margin-left:1.1rem;}
.left-off-canvas-menu{-webkit-backface-visibility:hidden;background:#d9d9d9;bottom:0;box-sizing:content-box;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar;overflow-x:hidden;overflow-y:auto;position:absolute;top:0;transition:transform 500ms ease 0s;width:12.5rem;z-index:1001;transform:translate3d(-100%, 0, 0);left:0;}
.left-off-canvas-menu *{-webkit-backface-visibility:hidden;}
ul.off-canvas-list{list-style-type:none;margin:0;padding:0;}
ul.off-canvas-list li a{border-bottom:none;color:#a6a6a6;display:block;padding:.875rem .75rem;transition:background 300ms ease;}
ul.off-canvas-list li a:hover{background:#b3b3b3;}
ul.off-canvas-list li a:active{background:#b3b3b3;}
@media print{
*{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
img{page-break-inside:avoid;}
img{max-width:100%!important;}
}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
a:hover{color:#1a5e82;text-decoration:underline;}
a:focus{color:#2075a3;text-decoration:underline;}
button{-webkit-appearance:none;-moz-appearance:none;background:transparent;padding:0;border:0;border-radius:2px;line-height:1;}
.off-canvas-list li a{text-decoration:none;}
body:not(.hide-focus-outline) :focus{outline:.125rem solid #006dc7;}
body:not(.hide-focus-outline) a:not(.button):focus{outline:none;box-shadow:inset 0 0 0 .125rem #006dc7!important;}
body:not(.hide-focus-outline) button:focus{outline:.125rem solid #006dc7;}
*{-webkit-tap-highlight-color:rgba(0,0,0,0);}
.ellipsis{overflow:hidden;-ms-text-overflow:ellipsis;text-overflow:ellipsis;white-space:nowrap!important;word-wrap:normal!important;}
button.standard-button{margin:0;padding:0;box-shadow:none;display:flex;align-items:center;justify-content:center;height:2.375rem;width:2.375rem;background-color:transparent;}
button.standard-button:hover{background-color:transparent;}
button.standard-button.dark-background{color:#cdcdcd;}
button.standard-button.dark-background:hover{color:#fff;}
.ellipsis{overflow:hidden;-ms-text-overflow:ellipsis;text-overflow:ellipsis;white-space:nowrap!important;word-wrap:normal!important;}
button.standard-button{margin:0;padding:0;box-shadow:none;display:flex;align-items:center;justify-content:center;height:2.375rem;width:2.375rem;background-color:transparent;}
button.standard-button:hover{background-color:transparent;}
button.standard-button.dark-background{color:#cdcdcd;}
button.standard-button.dark-background:hover{color:#fff;}
a.skip-link{position:absolute;top:-62.5rem;left:-62.5rem;height:0.0625rem;width:0.0625rem;text-align:left;overflow:hidden;font-size:.75rem;}
a.skip-link:active,a.skip-link:focus,a.skip-link:hover{left:0;top:0;height:auto;overflow:visible;text-align:center;background-color:#a234b5;color:#fff;display:block;width:100%;z-index:9999;outline:medium none;}
bb-username:before{content:'\00A0';width:0;background-image:url("data:image/svg+xml,%3c!-- Generator: Adobe Illustrator 17.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' enable-background='new 0 0 10 10' xml:space='preserve'%3e %3cpolygon fill='%23C36FD3' stroke='%23822798' stroke-linejoin='round' stroke-miterlimit='10' points='8.5,9.5 5,6.5 1.5,9.5 1.5,0.5 8.5,0.5 '/%3e %3c/svg%3e");background-size:contain;background-repeat:no-repeat;background-position:center;opacity:0.9;margin-right:0.2em;padding:0 0.45em;}
bb-username:before{display:none;}
bb-username{display:block;}
bb-username .username-accommodation-content .username-has-accommodation{display:none;}
/*! CSS Used from: https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/4729-9774406a534671fc.css */
.base #side-menu{display:flex;position:fixed;flex-direction:column;}
.base .side-menu-footer{margin-top:auto;padding:0.625rem;display:flex;flex-direction:column;font-size:.75rem;}
.base .side-menu-footer>.footer-link{padding:0.125rem;text-decoration:none;color:#cdcdcd;}
.base .side-menu-footer>.footer-link:hover{text-decoration:underline;}
.base #side-menu{background-color:#262626;top:0;left:auto;right:auto;width:100%;height:100%;}
.base #side-menu bb-username:before{display:none;}
@media only screen and (max-width: 39.9375em){
.base #side-menu{animation:hide-nav-menu-small 1s linear 0s 1;transform:none;transition:top 500ms cubic-bezier(0.55, 0, 0.1, 1),margin-top 500ms,z-index 0ms 500ms,opacity 200ms 450ms;top:-100%;}
.base #side-menu #base_tools{padding-bottom:3rem;}
}
@media only screen and (min-width: 40em) and (max-width: 63.9375em){
.base #side-menu{left:0;transition:left 500ms cubic-bezier(0.55, 0, 0.1, 1);}
}
@media only screen and (min-width: 40em){
.base #side-menu{width:12.5rem;text-align:left;}
.base #side-menu .exit-off-canvas{display:none;}
}
@media only screen and (min-width: 64em){
.base #side-menu{transform:none;transition:none;animation:fix-nav-menu-large 200ms linear 0s 1;}
}
.base #side-menu .bb-inner-wrap>header{height:3rem;display:table;background-color:#262626;border-bottom:2px solid #1d1d1d;box-shadow:0 3px 0 0 #262626;position:relative;width:100%;z-index:1;box-sizing:border-box;}
@media only screen and (min-width: 40em) and (max-width: 63.9375em){
.base #side-menu .bb-inner-wrap>header{height:3.5rem;}
}
@media only screen and (min-width: 64em){
.base #side-menu .bb-inner-wrap>header{height:6.6875rem;padding:0 1.875rem;}
}
.base #side-menu .bb-inner-wrap>header>*{display:table-cell;vertical-align:middle;float:none;}
.base #side-menu .bb-inner-wrap>header .menu-toggle{position:absolute;left:0.375rem;right:auto;top:0.3125rem;margin:0;padding:0.25rem 0.625rem;font-size:1.25rem;background-color:transparent;color:#e5e5e5;max-width:2.5rem;box-shadow:none;z-index:2;}
.base #side-menu .bb-inner-wrap>header .menu-toggle:hover{color:#fff;}
@media only screen and (min-width: 40em){
.base #side-menu .bb-inner-wrap>header .menu-toggle{top:0.6875rem;}
}
@media only screen and (min-width: 64em){
.base #side-menu .bb-inner-wrap>header .menu-toggle{display:none;}
}
@media only screen and (min-width: 40em){
.base #side-menu .bb-inner-wrap>header{padding:0;}
}
.base #side-menu .bb-inner-wrap>header .branding{position:relative;text-align:center;text-decoration:none;width:100%;color:#e5e5e5;}
.base #side-menu .bb-inner-wrap>header .branding .site-logo{max-height:calc(3rem - 1rem);max-width:9.375rem;}
@media only screen and (min-width: 40em){
.base #side-menu .bb-inner-wrap>header .branding .site-logo{max-height:calc(3.5rem - 1rem);max-width:8.125rem;}
}
@media only screen and (min-width: 64em){
.base #side-menu .bb-inner-wrap>header .branding .site-logo{max-height:calc(6.6875rem - 2rem);max-width:calc(12.5rem - 2rem);}
}
.base #side-menu .off-canvas-list .base-navigation-button:first-child{padding-top:0.1875rem;}
.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content{color:#e5e5e5;font-weight:400;transition:color 250ms, background-color 250ms;}
.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content:hover,.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content:focus,.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content:active{text-decoration:underline;}
.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content:hover{-webkit-text-fill-color:#fff;background-color:#1b1b1b;}
.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content:hover .label{background-color:#c23e37;}
.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content:focus{box-shadow:inset 0 0 0 2px #2f8df7!important;}
.base #side-menu .off-canvas-list .base-navigation-button.active .base-navigation-button-content{color:#fff;background-color:#000;}
.base #side-menu .off-canvas-list .base-navigation-button.active .base-navigation-button-content .label{color:#fff;background-color:#c23e37;}
.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content{position:relative;padding:0.875rem 0.75rem;padding-left:3rem;text-align:left;}
.base #side-menu .off-canvas-list .base-navigation-button.active .theme-border-left-active{border-left:4px solid #c56fd5;}
.base #side-menu .off-canvas-list span{vertical-align:middle;}
.base #side-menu .off-canvas-list .label{background-color:#262626;font-weight:600;padding:0.1875rem 0.5rem;position:absolute;right:0.75rem;top:50%;margin-top:-0.7em;}
/*! CSS Used from: Embedded */
.makeStylesroot-0-2-135{width:100%;display:flex;flex-direction:column;}
.makeStylesroot-0-2-135 bdi{display:block;overflow:hidden;direction:ltr;white-space:nowrap;text-overflow:ellipsis;}
.makeStylesuserPronunciation-0-2-139{width:100%;display:flex;}
/*! CSS Used keyframes */
@keyframes hide-nav-menu-small{0%,99%{opacity:0;}100%{opacity:1;}}
@keyframes fix-nav-menu-large{0%{text-shadow:0 0 0 rgba(0,0,0,0);}100%{text-shadow:0 0 0 rgba(255,255,255,0);}}
/*! CSS Used fontfaces */
@font-face{font-family:'Open Sans';font-style:normal;font-weight:300;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/9bea3ab0e3790117802b4b9b9a838a0b.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:italic;font-weight:300;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/84e6017f68a6e4af394ba73c97b26fcc.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/f161f7c04c5869a5ae82c1f5a47d6637.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:italic;font-weight:400;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/a35243340b59b98753ce27dc8eb5d2d2.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:600;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/ea999e8732854128345231d8c367ce2a.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:italic;font-weight:600;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/5e7f5980ccf27625f391f84e7bb5fd99.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:700;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/aac16533042097af8fc729ccb483511f.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:italic;font-weight:700;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/08c30329e59b7be1a4dc5efcb1a5cf23.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:800;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/44201fc12398ae08d2a5bf94b58b5aff.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:italic;font-weight:800;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/5d0839a9d4d5b89bfe4b166997311aa9.woff) format("woff");}


/*! CSS Used from: https://cdnjs.cloudflare.com/ajax/libs/bootswatch/5.2.3/morph/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
ul{padding-left:2rem;}
ul{margin-top:0;margin-bottom:1rem;}
a{color:var(--bs-link-color);text-decoration:underline;}
a:hover{color:var(--bs-link-hover-color);}
img{vertical-align:middle;}
button{border-radius:0;}
button:focus:not(:focus-visible){outline:0;}
button{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
button{text-transform:none;}
button{-webkit-appearance:button;}
button:not(:disabled){cursor:pointer;}
.alert{--bs-alert-bg:transparent;--bs-alert-padding-x:1rem;--bs-alert-padding-y:1rem;--bs-alert-margin-bottom:1rem;--bs-alert-color:inherit;--bs-alert-border-color:transparent;--bs-alert-border:0 solid var(--bs-alert-border-color);--bs-alert-border-radius:0.375rem;position:relative;padding:var(--bs-alert-padding-y) var(--bs-alert-padding-x);margin-bottom:var(--bs-alert-margin-bottom);color:var(--bs-alert-color);background-color:var(--bs-alert-bg);border:var(--bs-alert-border);border-radius:var(--bs-alert-border-radius);}
.alert{box-shadow:8px 8px 40px rgba(0,0,0,.15),inset 1px 1px 3px rgba(255,255,255,.5),inset -5px -5px 20px rgba(0,0,0,.05);-webkit-backdrop-filter:blur(3px);backdrop-filter:blur(3px);}
/*! CSS Used from: Embedded */
.ng-hide:not(.ng-hide-animate){display:none!important;}
/*! CSS Used from: https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/71973-9774406a534671fc.css */
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
/*! CSS Used from: https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/app-9774406a534671fc.css */
aside,header,nav{display:block;}
a{background-color:transparent;}
a:active,a:hover{outline:0;}
img{border:0;}
button{color:inherit;font:inherit;margin:0;}
button{overflow:visible;}
button{text-transform:none;}
button{-webkit-appearance:button;cursor:pointer;}
*,*:before,*:after{box-sizing:inherit;}
a:hover{cursor:pointer;}
img{max-width:100%;height:auto;}
img{-ms-interpolation-mode:bicubic;}
img{display:inline-block;vertical-align:middle;}
.label{display:inline-block;font-family:"Open Sans",sans-serif;font-weight:400;line-height:1;margin-bottom:auto;position:relative;text-align:center;text-decoration:none;white-space:nowrap;padding:.3125rem .5rem;font-size:.6875rem;background-color:#1874a4;color:#fff;}
.label.round{border-radius:1000px;}
.label.alert{background-color:#ff3b30;color:#fff;}
ul,li{margin:0;padding:0;}
div{margin:0;padding:0;}
a{color:#2075a3;line-height:inherit;text-decoration:underline;}
a:hover,a:focus{color:#1a5e82;}
a img{border:none;}
ul{font-family:inherit;font-size:.875rem;line-height:1.6;list-style-position:outside;margin-bottom:1.25rem;}
ul{margin-left:1.1rem;}
.left-off-canvas-menu{-webkit-backface-visibility:hidden;background:#d9d9d9;bottom:0;box-sizing:content-box;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar;overflow-x:hidden;overflow-y:auto;position:absolute;top:0;transition:transform 500ms ease 0s;width:12.5rem;z-index:1001;transform:translate3d(-100%, 0, 0);left:0;}
.left-off-canvas-menu *{-webkit-backface-visibility:hidden;}
ul.off-canvas-list{list-style-type:none;margin:0;padding:0;}
ul.off-canvas-list li a{border-bottom:none;color:#a6a6a6;display:block;padding:.875rem .75rem;transition:background 300ms ease;}
ul.off-canvas-list li a:hover{background:#b3b3b3;}
ul.off-canvas-list li a:active{background:#b3b3b3;}
@media print{
*{background:transparent!important;box-shadow:none!important;color:#000!important;text-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
img{page-break-inside:avoid;}
img{max-width:100%!important;}
}
.sr-only{position:absolute;width:0.0625rem;height:0.0625rem;margin:-0.0625rem;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
a:hover{color:#1a5e82;text-decoration:underline;}
a:focus{color:#2075a3;text-decoration:underline;}
button{-webkit-appearance:none;-moz-appearance:none;background:transparent;padding:0;border:0;border-radius:2px;line-height:1;}
.off-canvas-list li a{text-decoration:none;}
body:not(.hide-focus-outline) :focus{outline:.125rem solid #006dc7;}
body:not(.hide-focus-outline) a:not(.button):focus{outline:none;box-shadow:inset 0 0 0 .125rem #006dc7!important;}
body:not(.hide-focus-outline) button:focus{outline:.125rem solid #006dc7;}
*{-webkit-tap-highlight-color:rgba(0,0,0,0);}
.ellipsis{overflow:hidden;-ms-text-overflow:ellipsis;text-overflow:ellipsis;white-space:nowrap!important;word-wrap:normal!important;}
button.standard-button{margin:0;padding:0;box-shadow:none;display:flex;align-items:center;justify-content:center;height:2.375rem;width:2.375rem;background-color:transparent;}
button.standard-button:hover{background-color:transparent;}
button.standard-button.dark-background{color:#cdcdcd;}
button.standard-button.dark-background:hover{color:#fff;}
.ellipsis{overflow:hidden;-ms-text-overflow:ellipsis;text-overflow:ellipsis;white-space:nowrap!important;word-wrap:normal!important;}
button.standard-button{margin:0;padding:0;box-shadow:none;display:flex;align-items:center;justify-content:center;height:2.375rem;width:2.375rem;background-color:transparent;}
button.standard-button:hover{background-color:transparent;}
button.standard-button.dark-background{color:#cdcdcd;}
button.standard-button.dark-background:hover{color:#fff;}
a.skip-link{position:absolute;top:-62.5rem;left:-62.5rem;height:0.0625rem;width:0.0625rem;text-align:left;overflow:hidden;font-size:.75rem;}
a.skip-link:active,a.skip-link:focus,a.skip-link:hover{left:0;top:0;height:auto;overflow:visible;text-align:center;background-color:#a234b5;color:#fff;display:block;width:100%;z-index:9999;outline:medium none;}
bb-username:before{content:'\00A0';width:0;background-image:url("data:image/svg+xml,%3c!-- Generator: Adobe Illustrator 17.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' enable-background='new 0 0 10 10' xml:space='preserve'%3e %3cpolygon fill='%23C36FD3' stroke='%23822798' stroke-linejoin='round' stroke-miterlimit='10' points='8.5,9.5 5,6.5 1.5,9.5 1.5,0.5 8.5,0.5 '/%3e %3c/svg%3e");background-size:contain;background-repeat:no-repeat;background-position:center;opacity:0.9;margin-right:0.2em;padding:0 0.45em;}
bb-username:before{display:none;}
bb-username{display:block;}
bb-username .username-accommodation-content .username-has-accommodation{display:none;}
/*! CSS Used from: https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/4729-9774406a534671fc.css */
.base #side-menu{display:flex;position:fixed;flex-direction:column;}
.base .side-menu-footer{margin-top:auto;padding:0.625rem;display:flex;flex-direction:column;font-size:.75rem;}
.base .side-menu-footer>.footer-link{padding:0.125rem;text-decoration:none;color:#cdcdcd;}
.base .side-menu-footer>.footer-link:hover{text-decoration:underline;}
.base #side-menu{background-color:#262626;top:0;left:auto;right:auto;width:100%;height:100%;}
.base #side-menu bb-username:before{display:none;}
@media only screen and (max-width: 39.9375em){
.base #side-menu{animation:hide-nav-menu-small 1s linear 0s 1;transform:none;transition:top 500ms cubic-bezier(0.55, 0, 0.1, 1),margin-top 500ms,z-index 0ms 500ms,opacity 200ms 450ms;top:-100%;}
.base #side-menu #base_tools{padding-bottom:3rem;}
}
@media only screen and (min-width: 40em) and (max-width: 63.9375em){
.base #side-menu{left:0;transition:left 500ms cubic-bezier(0.55, 0, 0.1, 1);}
}
@media only screen and (min-width: 40em){
.base #side-menu{width:12.5rem;text-align:left;}
.base #side-menu .exit-off-canvas{display:none;}
}
@media only screen and (min-width: 64em){
.base #side-menu{transform:none;transition:none;animation:fix-nav-menu-large 200ms linear 0s 1;}
}
.base #side-menu .bb-inner-wrap>header{height:3rem;display:table;background-color:#262626;border-bottom:2px solid #1d1d1d;box-shadow:0 3px 0 0 #262626;position:relative;width:100%;z-index:1;box-sizing:border-box;}
@media only screen and (min-width: 40em) and (max-width: 63.9375em){
.base #side-menu .bb-inner-wrap>header{height:3.5rem;}
}
@media only screen and (min-width: 64em){
.base #side-menu .bb-inner-wrap>header{height:6.6875rem;padding:0 1.875rem;}
}
.base #side-menu .bb-inner-wrap>header>*{display:table-cell;vertical-align:middle;float:none;}
.base #side-menu .bb-inner-wrap>header .menu-toggle{position:absolute;left:0.375rem;right:auto;top:0.3125rem;margin:0;padding:0.25rem 0.625rem;font-size:1.25rem;background-color:transparent;color:#e5e5e5;max-width:2.5rem;box-shadow:none;z-index:2;}
.base #side-menu .bb-inner-wrap>header .menu-toggle:hover{color:#fff;}
@media only screen and (min-width: 40em){
.base #side-menu .bb-inner-wrap>header .menu-toggle{top:0.6875rem;}
}
@media only screen and (min-width: 64em){
.base #side-menu .bb-inner-wrap>header .menu-toggle{display:none;}
}
@media only screen and (min-width: 40em){
.base #side-menu .bb-inner-wrap>header{padding:0;}
}
.base #side-menu .bb-inner-wrap>header .branding{position:relative;text-align:center;text-decoration:none;width:100%;color:#e5e5e5;}
.base #side-menu .bb-inner-wrap>header .branding .site-logo{max-height:calc(3rem - 1rem);max-width:9.375rem;}
@media only screen and (min-width: 40em){
.base #side-menu .bb-inner-wrap>header .branding .site-logo{max-height:calc(3.5rem - 1rem);max-width:8.125rem;}
}
@media only screen and (min-width: 64em){
.base #side-menu .bb-inner-wrap>header .branding .site-logo{max-height:calc(6.6875rem - 2rem);max-width:calc(12.5rem - 2rem);}
}
.base #side-menu .off-canvas-list .base-navigation-button:first-child{padding-top:0.1875rem;}
.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content{color:#e5e5e5;font-weight:400;transition:color 250ms, background-color 250ms;}
.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content:hover,.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content:focus,.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content:active{text-decoration:underline;}
.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content:hover{-webkit-text-fill-color:#fff;background-color:#1b1b1b;}
.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content:hover .label{background-color:#c23e37;}
.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content:focus{box-shadow:inset 0 0 0 2px #2f8df7!important;}
.base #side-menu .off-canvas-list .base-navigation-button.active .base-navigation-button-content{color:#fff;background-color:#000;}
.base #side-menu .off-canvas-list .base-navigation-button.active .base-navigation-button-content .label{color:#fff;background-color:#c23e37;}
.base #side-menu .off-canvas-list .base-navigation-button .base-navigation-button-content{position:relative;padding:0.875rem 0.75rem;padding-left:3rem;text-align:left;}
.base #side-menu .off-canvas-list .base-navigation-button.active .theme-border-left-active{border-left:4px solid #c56fd5;}
.base #side-menu .off-canvas-list span{vertical-align:middle;}
.base #side-menu .off-canvas-list .label{background-color:#262626;font-weight:600;padding:0.1875rem 0.5rem;position:absolute;right:0.75rem;top:50%;margin-top:-0.7em;}
/*! CSS Used from: Embedded */
.makeStylesroot-0-2-135{width:100%;display:flex;flex-direction:column;}
.makeStylesroot-0-2-135 bdi{display:block;overflow:hidden;direction:ltr;white-space:nowrap;text-overflow:ellipsis;}
.makeStylesuserPronunciation-0-2-139{width:100%;display:flex;}
/*! CSS Used keyframes */
@keyframes hide-nav-menu-small{0%,99%{opacity:0;}100%{opacity:1;}}
@keyframes fix-nav-menu-large{0%{text-shadow:0 0 0 rgba(0,0,0,0);}100%{text-shadow:0 0 0 rgba(255,255,255,0);}}
/*! CSS Used fontfaces */
@font-face{font-family:'Open Sans';font-style:normal;font-weight:300;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/9bea3ab0e3790117802b4b9b9a838a0b.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:italic;font-weight:300;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/84e6017f68a6e4af394ba73c97b26fcc.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:400;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/f161f7c04c5869a5ae82c1f5a47d6637.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:italic;font-weight:400;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/a35243340b59b98753ce27dc8eb5d2d2.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:600;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/ea999e8732854128345231d8c367ce2a.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:italic;font-weight:600;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/5e7f5980ccf27625f391f84e7bb5fd99.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:700;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/aac16533042097af8fc729ccb483511f.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:italic;font-weight:700;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/08c30329e59b7be1a4dc5efcb1a5cf23.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:normal;font-weight:800;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/44201fc12398ae08d2a5bf94b58b5aff.woff) format("woff");}
@font-face{font-family:'Open Sans';font-style:italic;font-weight:800;src:url(https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/5d0839a9d4d5b89bfe4b166997311aa9.woff) format("woff");}